import React from 'react'
import DocumentMeta from 'react-document-meta';
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { client } from '../client'
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';


function LinearIndeterminate() {
  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
  );
}

const Contact = () => {

	// Meta Data
	const meta = {
		visualViewport: 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0',
		title: 'Creating Real | Contact',
		description: 'Contact Creating Real for any inquiries, questions, or concerns. We try to respond within 3-5 business days!',
		canonical: 'https://www.creatingreal.com/contact',
		meta: {
			charset: 'utf-8',
			name: {
				keywords: 'creating real, software development, software, creating real contact, contact, contact us, contact creating real'
			}
		}
	}

  // eslint-disable-next-line
  const [name, setName] = useState('');
  // eslint-disable-next-line
  const [email, setEmail] = useState('');
  // eslint-disable-next-line
  const [subject, setSubject] = useState('');
  // eslint-disable-next-line
  const [message, setMessage] = useState('');

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const sendMessage = () => {

    setLoading(true);

    // Create a document object with the necessary fields
    const contactMessage = {
      _type: 'contact', // This type should match what you've defined in your Sanity schema,
      name: name,
      email: email,
      subject: subject,
      message: message
    };

    // Use the Sanity client to create a new document
    client.create(contactMessage)
      .then((res) => {
        console.log('Message sent!', res);
        // Here you might want to clear the form or give feedback to the user that the message was sent successfully
        setName('');
        setEmail('');
        setSubject('');
        setMessage('');

        setSuccess(true);

        setLoading(false);
      })
      .catch((err) => {
        console.error('Failed to send message:', err);
        setLoading(false);
        setError(true);
        // Handle errors, maybe show a message to the user
      });
  };

  return (
    <div className="min-h-screen">
			
			<DocumentMeta {...meta} />

      <Navbar />

      <body className=" text-foregroundDark min-h-screen">
        <section className="text-black body-font ">

          <div className="px-5 py-24 max-w-6xl mx-auto grid lg:grid-cols-3 grid-rows-2 lg:grid-rows-1 gap-6">

            <div id="contactform" className="relative overflow-x-hidden h-full lg:col-span-1 row-span-1 bg-background dark:bg-stone-800  p-6 shadow-xl rounded-3xl">
              <div className="absolute w-[1000px] top-0 left-0">
                {
                  loading ? <LinearIndeterminate /> : null
                }
              </div>

              <h2 className="text-black dark:text-gray-200 text-2xl mb-1 mt-6 laptop:mt-0 font-MetropolisBold title-font mx-2 text-left"> Let's Get in Touch! </h2>
              <p className="leading-relaxed mb-5 text-black dark:text-gray-200 font-MetropolisMedium mx-2 text-left"> We try to respond within 3-5 business days! </p>

              <div className="mb-4">
                <p for="name" className="text-sm text-black dark:text-gray-200 font-MetropolisBold ml-4 text-left mb-2">Full Name</p>
                <input value={name} onChange={(e) => setName(e.target.value)} type="text" id="name" name="name" className="w-full bg-white  font-MetropolisRegular rounded-full border dark:bg-backgroundDark dark:text-gray-200 border-gray-300 dark:border-stone-700 focus:border-tertiary focus:ring-2 dark:focus:ring-0 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
              </div>

              <div className="mb-4">
                <p for="email" className="text-sm text-black dark:text-gray-200 font-MetropolisBold ml-4 text-left mb-2">Email Address</p>
                <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" id="email" name="email" className="w-full bg-white font-MetropolisRegular rounded-full border dark:bg-backgroundDark dark:text-gray-200 border-gray-300 dark:border-stone-700 focus:border-tertiary focus:ring-2 dark:focus:ring-0 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
              </div>

              <div className="mb-4">
                <p for="subject" className="text-sm text-black dark:text-gray-200 font-MetropolisBold ml-4 text-left mb-2">Subject</p>
                <input value={subject} onChange={(e) => setSubject(e.target.value)} type="email" id="subject" name="subject" className="w-full bg-white font-MetropolisRegular rounded-full dark:bg-backgroundDark dark:text-gray-200 border border-gray-300 dark:border-stone-700 focus:border-tertiary focus:ring-2 dark:focus:ring-0 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
              </div>

              <div className="mb-4">
                <p for="message" className="text-sm text-black dark:text-gray-200 font-MetropolisBold ml-4 text-left mb-2">Message</p>
                <textarea value={message} onChange={(e) => setMessage(e.target.value)} id="message" name="message" className="w-full bg-white rounded-3xl font-MetropolisRegular dark:bg-backgroundDark dark:text-gray-200 border border-gray-300 dark:border-stone-700 focus:border-tertiary focus:ring-2 dark:focus:ring-0 focus:ring-blue-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
              </div>

              <button id="sendmessage" onClick={sendMessage} className="w-full text-white bg-tertiary border-0 py-2 px-8 focus:outline-none hover:scale-105 transform transition duration-300 ease-in-out rounded-full shadow-xl text-md font-MetropolisBold mt-3">Send Message</button>

              <div id="status">

                {
                  success ? <h1 className="text-green-500 mt-3 font-MetropolisExtraBold text-md">Message successfully sent!</h1> : null
                }

                {
                  error ? <h1 className="text-red-500 mt-3 font-MetropolisExtraBold text-lg">Error sending message</h1> : null
                }

              </div>
              <p className="text-xs text-gray-500 dark:text-gray-400 font-MetropolisSemiBold mt-3">By clicking 'Send Message' you agree to sending all form data above to our represented company email and potentially receiving emails in exchange at the provided email address above. </p>

            </div>

            <div className="h-full lg:col-span-2 col-span-1 row-span-1 bg-white dark:bg-black rounded-3xl overflow-hidden p-6 flex items-end justify-start relative shadow-xl">
              <iframe width="100%" height="100%" className="absolute inset-0" frameborder="0" title="map" marginheight="0" marginwidth="0" scrolling="no" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224444.7552874215!2d-81.50781737170205!3d28.481073431834748!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e773d8fecdbc77%3A0xac3b2063ca5bf9e!2sOrlando%2C%20FL!5e0!3m2!1sen!2sus!4v1690821902205!5m2!1sen!2sus"
                // style="filter: grayscale(1) contrast(1.2) opacity(0.4)"

                style={{
                  filter: "grayscale(1) contrast(1.2) opacity(0.4)"
                }}
              >

              </iframe>

              <div className="bg-white dark:bg-stone-800 shadow-xl grid grid-rows-3 lg:grid-cols-2 relative w-full py-6 rounded-3xl shadow-md">
                <div className="px-6 row-span-1 lg:row-span-3">
                  <h2 className="title-font font-MetropolisBold text-black dark:text-gray-200 st text-sm text-left"> LOCATION </h2>
                  <h2 className="text-left"><Link to="https://goo.gl/maps/EevE6cf51cSexe67A" className="underline mt-1 text-black dark:text-gray-200 font-MetropolisMedium text-left"> Orlando, FL USA </Link> </h2>
                </div>

                <div className="px-6 col-span-1 row-span-2 lg:row-span-3">
                  <h2 className="title-font font-MetropolisBold text-black dark:text-gray-200 st text-sm text-left "> EMAIL </h2>
                  <h2 className="text-left"><Link to="mailto:support@creatingreal.com" className="leading-relaxed text-black dark:text-gray-200 font-MetropolisMedium">support@creatingreal.com</Link> </h2>
                  <h2 className="title-font font-MetropolisBold text-black dark:text-gray-200 st text-sm mt-4 text-left"> PHONE </h2>
                  <p className="leading-relaxed text-black dark:text-gray-200 font-MetropolisMedium text-left"> +1 (321) 209-4070 </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </body>

      <Footer />

    </div>
  )
}

export default Contact