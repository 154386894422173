import React from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";

const Navbar = () => {

	const [page, setPage] = useState("undefined");
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		const path = window.location.pathname;
		const currentPage = path.split("/").pop();
		setPage(currentPage);
	}, []);

	const toggleMenu = () => {
		setIsOpen(!isOpen);
	};

	const navbarLinks = [
		// { path: '/software', label: 'Software' },
		{ path: '/ourteam', label: 'Our Team' },
		{ path: '/newsroom', label: 'Newsroom' },
		{ path: '/contact', label: 'Contact Us' },
		// { path: '/apply', label: 'Apply' }
	];

	return (
		<div>
			<main className="relative overflow-hidden">
				<nav>
					<div className="max-w-6xl mx-auto px-4">
						<div className="flex justify-between">
							<div className="flex space-x-3">
								<Link
									to="/"
									className="flex items-center px-2"
								>
									<img
										src={require("../assets/images/creatingRealLogo.png")}
										alt="logo"
										className="h-12 w-12 mr-2 my-2 rounded-full hover:bg-gray-400"
									/>
								</Link>

								<div className="hidden laptop:flex items-center space-x-3 font-MetropolisSemiBold">
									{navbarLinks.map(
										(
											link
										) => (
											<Link
												key={
													link.label
												}
												to={
													link.path
												}
												className={`py-2 px-6 flex ${page ===
													link.path.substring(
														1
													)
													? "text-white bg-tertiary rounded-full"
													: "text-black dark:text-white hover:bg-gray-400 hover:bg-opacity-25 rounded-full hover:scale-105 transition duration-300 ease-in-out"
													}`}
											>
												{
													link.label
												}
											</Link>
										)
									)}
								</div>
							</div>

							<div className="laptop:hidden flex items-center">
								<button
									onClick={
										toggleMenu
									}
									className="outline-none mobile-menu-button"
								>
									{/* If isOpen, ClearRoundedIcon */}
									{/* If !isOpen, MenuRoundedIcon */}
									{isOpen ? (
										<ClearRoundedIcon className="text-black dark:text-white" />
									) : (
										<MenuRoundedIcon className="text-black dark:text-white" />
									)}
								</button>
							</div>
						</div>
					</div>
					<div
						className={`${isOpen
							? "block"
							: "hidden"
							} mobile-menu font-MetropolisSemiBold`}
					>
						<ul className="laptop:hidden">
							{navbarLinks.map(
								(
									link
								) => (
									<li
										key={
											link.label
										}
										className="mt-1 mb-1"
									>
										<Link
											to={
												link.path
											}
											className={`py-2 px-6 flex ${page ===
												link.path.substring(
													1
												)
												? "text-white bg-tertiary rounded-full hover:text-white mx-4"
												: "text-black dark:text-white hover:bg-gray-700 hover:bg-opacity-25 rounded-full mx-4 hover:scale-105 transition duration-300 ease-in-out"
												}`}
										>
											{
												link.label
											}
										</Link>
									</li>
								)
							)}
						</ul>
					</div>
				</nav>
			</main>
		</div>
	);
};







export default Navbar;