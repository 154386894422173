import React from 'react'
import DocumentMeta from 'react-document-meta';
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { useState, useEffect } from 'react'
import { client, urlFor } from '../client'

// const teamMembers =
// 	[
// 		{
// 			name: "Joshua Lim",
// 			role: "Founder & Senior Developer",
// 			image: require("../assets/images/team/joshua_lim.jpg"),
// 		},
// 		{
// 			name: "Mustafa Masody",
// 			role: "Co-Founder & Senior Developer",
// 			image: require("../assets/images/team/mustafa_masody.jpg"),
// 		},
// 		{
// 			name: "Siamak Erami",
// 			role: "Minority Owner & Junior Developer",
// 			image: require("../assets/images/team/siamak_erami.jpg"),
// 		},
// 		{
// 			name: "Derek Ballas",
// 			role: "Business and Investment Analyst",
// 			image: require("../assets/images/team/derek_ballas.jpeg"),
// 		},
// 		{
// 			name: "Sebastian Garcia",
// 			role: "Software Developer Intern",
// 			image: require("../assets/images/team/sebastian_garcia.jpg"),
// 		},
// 		{
// 			name: "Luis Hurtado",
// 			role: "Software Developer Intern",
// 			image: require("../assets/images/team/luis_hurtado.jpg"),
// 		},
// 		{
// 			name: "Jasmine Narayan",
// 			role: "Software Developer Intern",
// 			image: require("../assets/images/team/jasmine_narayan.png"),
// 		},
// 		{
// 			name: "Aaron Tran",
// 			role: "Software Developer Intern",
// 			image: require("../assets/images/team/aaron_tran.jpg"),
// 		},
// 	];

const OurTeam = () => {

	// Meta Data
	const meta = {
		visualViewport: 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0',
		title: 'Creating Real | Our Team',
		description: 'Meet the team behind the products you love! Creating Real is a software development company that specializes in creating real products for real people.',
		canonical: 'https://www.creatingreal.com/ourteam',
		meta: {
			charset: 'utf-8',
			name: {
				keywords: 'creating real, software development, software, creating real newsroom, newsroom, news, creating real news'
			}
		}
	}

  const [team, setTeam] = useState([]);

  useEffect(() => {

    const query = "*[_type == 'employee']";

    client.fetch(query).then((data) => {

      // Sort by index
      data.sort((a, b) => {
        return a.index - b.index;
      });

      // Set the data
      setTeam(data);

    });

  }, []);

  return (
    <div className="min-h-screen">

      <DocumentMeta {
        ...meta
      } />

      <Navbar />
      <main className="relative overflow-hidden">

        {/* Hero /}
        <div className="min-h-screen">
          {/* Video in Background from YouTube /}
          <div className="relative bg-black h-screen">
            <iframe
              className="absolute inset-0 w-full h-full"
              src="https://www.youtube.com/embed/7e90gBu4pas?autoplay=1&mute=1&controls=0&loop=1&playlist=7e90gBu4pas"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <div className="absolute inset-0 bg-black bg-opacity-50"></div>
            <div className="absolute inset-0 flex items-center justify-center">
              <h1 className="text-4xl font-bold text-white">
                Turn
                Dreams,
                Into
                Reality.
              </h1>
            </div>
            </div>
          </div>*/}

        <section className="text-gray-600 body-font min-h-screen">
          <div className="container px-5 py-24 mx-auto">
            <div className="flex flex-col text-center w-full mb-20">
              <h1 className="text-4xl font-MetropolisBlack title-font mb-4 text-gray-900 dark:text-gray-200 tracking-widest">
                OUR TEAM
              </h1>
              <p className="lg:w-2/3 mx-auto leading-relaxed text-gray-900 dark:text-gray-200 text-lg font-MetropolisSemiBold">
                Meet the group behind the products you love!
              </p>
            </div>
            <div className="flex flex-wrap -m-2">
              {team.map((member) => (
                <a href={`/ourteam/employee?id=` + member.id} key={member.name} className="p-4 lg:w-1/3 w-full hover:scale-105 transition-transform duration-300 ease-in-out">
                  <div className="h-full flex text-left items-center border-gray-200 border p-4 px-6 rounded-3xl shadow-xl bg-gray-100 dark:bg-stone-800 dark:border-stone-900">
                    <img
                      alt="team"
                      className="tablet:w-32 tablet:h-32 mobile:w-20 mobile:h-20 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4 shadow-2xl inline-block p-2"
                      src={member.image ? urlFor(member.image).url() : "https://via.placeholder.com/150"}
                    />
                    <div className="flex-grow">
                      <h2 className="text-gray-900 dark:text-gray-100 title-font font-MetropolisBold text-xl">
                        {member.name}
                      </h2>
                      <p className="text-gray-500 dark:text-gray-300 font-MetropolisMedium text-sm">
                        {member.position}
                      </p>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>

        </section>
      </main>

      <Footer />

    </div>
  );
};




export default OurTeam;