import React from 'react'
import DocumentMeta from 'react-document-meta';
import { useState, useEffect } from 'react'
import { client, urlFor } from '../client'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const NewsroomIndividual = () => {

    let urlParams = new URLSearchParams(window.location.search);

    let slug = urlParams.get('id');

    const [newsletterData, setNewsletterData] = useState([]);

    useEffect(() => {

        const query = `*[_type == 'newsroom' && id == '${slug}']`;

        client.fetch(query).then((data) => {

            // Convert timestamp to MMMM dd, yyyy
            for (var i = 0; i < data.length; i++) {
                let month = data[i].timestamp.substring(0, 2);
                const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                data[i].timestamp = months[parseInt(month) - 1] + " " + data[i].timestamp.substring(3, 5) + ", " + data[i].timestamp.substring(6, 10);
            }

            // Split content by newline
            for (var i = 0; i < data.length; i++) {
                data[i].content = data[i].content.split("\n").map((item, key) => {

                    // Add tab, and add another break after
                    return <span key={key}>{item}<br /></span>

                });
            }

            // Set the data
            setNewsletterData(data);

        });

    }, [slug]);

    return (
        <div>

            <DocumentMeta {...{
                visualViewport: 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0',
                title: newsletterData.map((newsletter) => newsletter.title) + "",
                description: newsletterData.map((newsletter) => newsletter.content) + "",
                canonical: 'https://creatingreal.com/newsroom/article?id=' + slug,
                openGraph: {
                    title: newsletterData.map((newsletter) => newsletter.title),
                    description: newsletterData.map((newsletter) => newsletter.content),
                    url: 'https://creatingreal.com/newsroom/article?id=' + slug,
                    type: 'article',
                    article: {
                        publishedTime: newsletterData.map((newsletter) => newsletter.timestamp),
                        modifiedTime: newsletterData.map((newsletter) => newsletter.timestamp),
                        section: 'Newsroom',
                        tags: newsletterData.map((newsletter) => newsletter.tags)
                    }
                },
                meta: {
                    charset: 'utf-8',
                    name: {
                        keywords: 'creating real, creating real newsroom, creating real newsroom article, creating real newsroom article, ' + newsletterData.map((newsletter) => newsletter.title)
                    }
                }
            }}/>

            <Navbar />

            <div className="min-h-screen bg-background dark:bg-backgroundDark">

                {newsletterData.map((newsletter, index) => (
                    <div key={index} className='min-h-screen'>

                        {/* Image, Title, Author, Timestamp */}
                        {/* Check if Image is empty */}
                        {newsletter.image ? (
                            <div className='h-96 max-w-6xl rounded-3xl my-10 overflow-hidden mx-auto bg-cover bg-center bg-no-repeat shadow-xl' style={{ backgroundImage: `url(${urlFor(newsletter.image).url()})` }}>
                                <div className='h-full w-full bg-gradient-to-t from-gray-500 to-transparent p-10 flex flex-row items-end'>
                                    <div className='px-10'>
                                        <div className='grid justify-start'>
                                            <h1 className='text-4xl text-white font-MetropolisBold text-left'>{newsletter.title}</h1>
                                            <p className='text-white font-MetropolisLight text-left'>{newsletter.timestamp} | {newsletter.author}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className='h-96 max-w-6xl rounded-3xl my-10 overflow-hidden mx-auto bg-gradient-to-tl from-primary via-secondary to-tertiary p-10 shadow-xl'>
                                <div className='h-full w-full flex flex-row items-end'>
                                    <div className='grid justify-start px-10'>
                                        <h1 className='text-4xl text-white font-MetropolisBold text-left'>{newsletter.title}</h1>
                                        <p className='text-white font-MetropolisLight text-left'>{newsletter.timestamp} | {newsletter.author}</p>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Content */}
                        <div className='max-w-6xl mx-auto p-10'>
                            <p className='text-gray-600 dark:text-gray-300 font-MetropolisMedium text-lg text-left'>{newsletter.content}</p>
                        </div>

                        {/* Tags */}
                        <div className='max-w-6xl mx-auto px-10 py-2 mb-20'>
                            <p className='text-gray-600 dark:text-gray-300 font-MetropolisMedium text-lg text-left'>Tags: {newsletter.tags.map((tag, index) => (
                                <span key={index} className='inline-block bg-tertiary text-white font-MetropolisMedium text-sm px-4 py-2 mx-2 rounded-full'>{tag}</span>
                            ))}</p>
                        </div>

                        {/* Back Button
                    <a href='javascript:history.back()' className='inline-block bg-tertiary h-8 w-8 rounded-full text-white'><ChevronLeftRoundedIcon /></a> */}

                        {/* Share to Socials
                    <div className='max-w-6xl mx-auto p-10'>
                        <p className='text-gray-600 dark:text-gray-300 font-MetropolisMedium text-lg text-left mb-10'>Share to:
                            <a href='#' className='text-tertiary dark:text-white font-MetropolisMedium text-lg text-left mx-2'>Facebook</a>
                            <a href='#' className='text-tertiary dark:text-white font-MetropolisMedium text-lg text-left mx-2'>Twitter</a>
                            <a href='#' className='text-tertiary dark:text-white font-MetropolisMedium text-lg text-left mx-2'>LinkedIn</a>
                        </p>
                    </div> */}

                    </div>
                ))}

            </div>

            <Footer />

        </div>
    )
}


export default NewsroomIndividual