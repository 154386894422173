import React from 'react'
import DocumentMeta from 'react-document-meta';
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { useState, useEffect } from 'react'
import { client, urlFor } from '../client'

const Newsroom = () => {

	// Meta Data
	const meta = {
		visualViewport: 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0',
		title: 'Creating Real | Newsroom',
		description: 'Stay up to date with everything Creating Real. From product launches to company updates, our newsroom is the best place to stay informed.',
		canonical: 'https://www.creatingreal.com/newsroom',
		meta: {
			charset: 'utf-8',
			name: {
				keywords: 'creating real, software development, software, creating real newsroom, newsroom, news, creating real news'
			}
		}
	}

  const [newsletters, setNewsletters] = useState([]);

  useEffect(() => {

    const query = "*[_type == 'newsroom']";

    // MM/dd/yyyy
    client.fetch(query).then((data) => {

      // Convert timestamp to MMM dd
      for (var i = 0; i < data.length; i++) {
        let month = data[i].timestamp.substring(0, 2);
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        data[i].month = months[parseInt(month) - 1];
      }

      // Check if content is greater than 140 characters, if so, add "..." to .preview
      for (var i = 0; i < data.length; i++) {
        if (data[i].content.length > 140) {
          data[i].preview = data[i].content.substring(0, 140) + "...";
        } else {
          data[i].preview = data[i].content;
        }
      }

      // take out duplicates witht he same title
      let unique = [];
      let titles = [];
      for (var i = 0; i < data.length; i++) {
        if (!titles.includes(data[i].title)) {
          unique.push(data[i]);
          titles.push(data[i].title);
        }
      }

      data = unique;
      // sort by chronological order, the dates are in format: 10/04/2022
      let sortedData = data.sort((a, b) => {
        let aDate = a.timestamp.split("/");
        let bDate = b.timestamp.split("/");
        return new Date(bDate[2], bDate[0], bDate[1]) - new Date(aDate[2], aDate[0], aDate[1]);
      });

      // Set the data
      setNewsletters(sortedData);
      console.log(data);

    });

  }, []);

  return (

    <div className="min-h-screen">

      <DocumentMeta {
        ...meta
      } />

      <Navbar />

      {/* Video in Background from YouTube */}
      {/* <div className="relative bg-black h-screen">
        <iframe
          className="absolute inset-0 w-full h-full"
          src="https://www.youtube.com/embed/7e90gBu4pas?autoplay=1&mute=1&controls=0&loop=1&playlist=7e90gBu4pas"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="absolute inset-0 flex items-center justify-center">
          <h1 className="text-4xl font-bold text-white">
            Stay Up To Date... We Know We Will.
          </h1>
        </div>
      </div> */}

      {/* Section */}
      <section className="min-h-screen">
        <div className="container px-5 py-24 mx-auto">
          {/* Title */}
          <div className="flex flex-col text-center w-full mb-20">
            <h1 className="text-4xl font-MetropolisBlack title-font mb-4 text-gray-900 dark:text-gray-200 tracking-widest">
              NEWSROOM
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-gray-900 dark:text-gray-200 text-lg font-MetropolisSemiBold">
              Stay up to date with everything Creating Real.
              {/* From product launches to company updates, our newsroom is the best place to stay informed. */}
            </p>
          </div>

          {/* Articles */}
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 max-w-6xl mx-auto my-20">

            {newsletters.map((newsletter, index) => (

              // Article Card
              <article className="overflow-hidden bg-foreground dark:bg-foregroundDark font-MetropolisBold rounded-3xl shadow-lg hover:scale-105 transform transition duration-500 ease-in-out" key={index}>

                {/* Link to Article */}
                <a href={`/newsroom/article?id=` + newsletter.id} className="no-underline text-black">

                  <section className="text-gray-600 body-font p-6">

                    <div className="h-full flex items-start">

                      {/* Date */}
                      <div className="w-12 flex-shrink-0 flex flex-col text-center leading-none">

                        {/* Month (converted to MMM) */}
                        <span className="uppercase text-tertiary pb-2 mb-2 border-b-2 border-tertiary font-MetropolisBold">{newsletter.month}</span>

                        {/* Day */}
                        <span className="font-medium text-lg text-gray-800 dark:text-gray-300 title-font leading-none font-MetropolisMedium">{newsletter.timestamp.substring(3, 5)}</span>

                      </div>

                      {/* Content */}
                      <div className="pl-6 justify-left w-full">

                        {/* Category */}
                        <h2 className="text-xs rounded p-2 bg-blue-100 dark:bg-tertiary text-tertiary dark:text-white font-MetropolisSemiBold mb-3 text-left uppercase">{newsletter.category}</h2>

                        {/* Title */}
                        <h1 className="text-xl text-gray-900 dark:text-gray-200 mb-3 font-MetropolisBold text-left">{newsletter.title}</h1>

                        {/* Preview */}
                        <p className="font-MetropolisMedium text-left text-sm">{newsletter.preview}</p>

                      </div>

                    </div>

                  </section>

                </a>

              </article>

            ))}

          </div>

        </div>

      </section>

      <Footer />

    </div>

  )

}

export default Newsroom