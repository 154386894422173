import React from 'react'
import { useState, useEffect } from 'react'
import { client, urlFor } from '../client'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import DocumentMeta from 'react-document-meta';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { GitHub } from '@mui/icons-material';

const TeamInividual = () => {

    const TikTokIcon = ({ color = "#000000" }) => {
        return (
            <svg
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 50"
            width="100%"
            height="100%"
            >
            <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
            </svg>
        );
    };

    let urlParams = new URLSearchParams(window.location.search);

    let slug = urlParams.get('id');

    const [employeeData, setEmployeeData] = useState([]);

    useEffect(() => {

        console.log(slug);

        const query = `*[_type == 'employee' && id == '${slug}']`;

        client.fetch(query).then((data) => {

            // Sort socials keys in the following order: (Personal, Facebook, Instagram, TikTok, Twitter, YouTube, LinkedIn, GitHub)
            for (var i = 0; i < data.length; i++) {
                let socials = data[i].socials;
                let sortedSocials = {};
                let keys = Object.keys(socials);
                let order = ['personal', 'facebook', 'instagram', 'tiktok', 'twitter', 'youtube', 'linkedin', 'github'];
                order.forEach(function (key) {
                    if (keys.includes(key)) {
                        sortedSocials[key] = socials[key];
                    }
                });
                data[i].socials = sortedSocials;
            }

            // Format phone number +1 (123) 456-7890
            // for (var i = 0; i < data.length; i++) {
            //     let phone = data[i].phone;
            //     data[i].phone = `+1 (${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6, 10)}`;
            // }

            // Split content by newline
            for (var i = 0; i < data.length; i++) {
                data[i].bio = data[i].bio.split("\n").map((item, key) => {

                    // Add tab, and add another break after
                    return <span key={key}>{item}<br /></span>

                });
            }

            // Set the data
            setEmployeeData(data);

        });

    }, [slug]);

    return (
        <div>

            <DocumentMeta {...{
                visualViewport: 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0',
                title: employeeData.map((employee) => employee.name) + " | Creating Real",
                description: employeeData.map((employee) => employee.bio) + "",
                canonical: "https://www.creatingreal.com/ourteam/employee?id=" + slug,
                meta: {
                    charset: 'utf-8',
                    name: {
                        keywords: 'creating real, employee, team' + employeeData.map((employee) => employee.name)
                    }
                }
            }}/>

            <Navbar />

            <div className="min-h-screen bg-background dark:bg-backgroundDark">

                {employeeData.map((employee) => (
                    <div key={employee._id} className="flex flex-col items-center justify-center mt-20">
                        
                        {/* Image, Name, Position */}
                        <div className="flex flex-col items-center justify-center">
                            {/* Image */}
                            <img src={employee.image ? urlFor(employee.image).url() : "https://via.placeholder.com/150"}
                                alt={employee.name} className="w-40 h-40 rounded-full object-cover hover:scale-105 transform transition duration-500 ease-in-out shadow-xl" />
                            {/* Name */}
                            <h1 className="text-4xl font-MetropolisBold text-gray-900 dark:text-gray-200 mt-4">{employee.name}</h1>
                            {/* Position */}
                            <h2 className="text-md font-MetropolisSemiBold text-gray-900 dark:text-gray-200 mt-1">{employee.position}</h2>
                            {/* Department */}
                            {/* <h2 className="text-md font-MetropolisSemiBold text-tertiary mt-1">{employee.department}</h2> */}
                        </div>

                        {/* Hometown | Based In */}
                        <div className="text-center text-gray-900 dark:text-gray-200 font-MetropolisMedium text-sm mt-2">
                            <HomeRoundedIcon fontSize='small' />
                            {" "}{employee.hometown}
                            {" "}•{" "}
                            <LocationOnRoundedIcon fontSize='small' />
                            {" "}{employee.based}
                        </div>

                        {/* Contact Info */}
                        <div className="flex flex-row space-x-4 mt-6">
                            {/* <a href={`tel:${employee.phone}`} className="bg-tertiary text-white font-MetropolisMedium py-2 px-4 rounded-full text-sm shadow-xl hover:scale-105 transform transition duration-500 ease-in-out hover:bg-opacity-80">
                                <LocalPhoneRoundedIcon fontSize='small' />
                                {" "} {employee.phone}
                            </a> */}
                            <a href={`mailto:${employee.email}`} className="bg-tertiary text-white font-MetropolisMedium py-2 px-4 rounded-full text-sm shadow-xl hover:scale-105 transform transition duration-500 ease-in-out hover:bg-opacity-80">
                                <EmailRoundedIcon fontSize='small' />
                                {" "} {employee.email}
                            </a>
                        </div>

                        {/* Bio */}
                        <div className="max-w-4xl mx-6 md:mx-auto my-6 p-4 text-black dark:text-white font-MetropolisMedium text-md text-center">
                            {employee.bio}
                        </div>

                        {/* Socials & Links */}
                        <h1 className="text-2xl font-MetropolisBold text-gray-900 dark:text-gray-200 mt-10">Connect with Me</h1>
                        <div className="grid grid-cols-4 sm:grid-flow-col sm:auto-cols-max justify-center gap-4 mt-6 mb-20 mx-6">
                            {Object.keys(employee.socials).map((social, index) => (
                                <a href={employee.socials[social]} className="bg-tertiary text-white font-MetropolisMedium p-2 md:p-4 text-sm rounded-full shadow-xl hover:scale-105 transform transition duration-500 ease-in-out hover:bg-opacity-80">
                                    {/* Toggle key for social icon */}
                                    {social === 'facebook' ? <FacebookIcon fontSize='small' /> : null}
                                    {social === 'instagram' ? <InstagramIcon fontSize='small' /> : null}
                                    {social === 'youtube' ? <YouTubeIcon fontSize='small' /> : null}
                                    {social === 'linkedin' ? <LinkedInIcon fontSize='small' /> : null}
                                    {social === 'personal' ? <LanguageRoundedIcon fontSize='small' /> : null}
                                    {social === 'twitter' ? <XIcon fontSize='small' /> : null}
                                    {social === 'tiktok' ? <div style={{ width: "20px" }}><TikTokIcon color='white' /></div> : null}
                                    {social === 'github' ? <GitHub fontSize='small' /> : null}
                                </a>
                            ))}
                        </div>

                    </div>
                ))}

            </div>
                
            <Footer />
        
        </div>
    )
};

export default TeamInividual;