import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import DocumentMeta from 'react-document-meta';
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import HourglassBottomRoundedIcon from "@mui/icons-material/HourglassBottomRounded";
import UpdateRoundedIcon from "@mui/icons-material/UpdateRounded";
import { useState, useEffect } from 'react'
import { client, urlFor } from '../client'


const Home = () => {

	// Log the environment variables
	console.log(process.env)

	// Meta Data
	const meta = {
		visualViewport: 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0',
		title: 'Creating Real | Home',
		description: 'Creating Real is a software development company that focuses on creating products that are designed to last. Our products are built with the user in mind, and are designed to be the best in the industry.',
		canonical: 'https://www.creatingreal.com',
		meta: {
			charset: 'utf-8',
			name: {
				keywords: 'creating real, software development, crcl, crcl business',
			}
		}
	}

	const [newsletters, setNewsletters] = useState([]);

	useEffect(() => {

		const query = "*[_type == 'newsroom']";

		client.fetch(query).then((data) => {

			for (var i = 0; i < data.length; i++) {
				let month = data[i].timestamp.substring(0, 2);
				const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
				data[i].month = months[parseInt(month) - 1];
			}

			// Check if content is greater than 140 characters, if so, add "..." to .preview
			for (var i = 0; i < data.length; i++) {
				if (data[i].content.length > 140) {
					data[i].preview = data[i].content.substring(0, 140) + "...";
				} else {
					data[i].preview = data[i].content;
				}
			}
			// take out duplicates witht he same title
			let unique = [];
			let titles = [];
			for (var i = 0; i < data.length; i++) {
				if (!titles.includes(data[i].title)) {
					unique.push(data[i]);
					titles.push(data[i].title);
				}
			}

			data = unique;

			// sort by chronological order, the dates are in format: 10/04/2022
			let sortedData = data.sort((a, b) => {
				let aDate = a.timestamp.split("/");
				let bDate = b.timestamp.split("/");
				return new Date(bDate[2], bDate[0], bDate[1]) - new Date(aDate[2], aDate[0], aDate[1]);
			});

			// set to most recent 3, check if there are 3 or more
			if (sortedData.length >= 3) {
				sortedData = sortedData.slice(0, 3);
			}

			// Set the data
			setNewsletters(sortedData);

		});

	}, []);

	return (
		<div>

			<DocumentMeta {...meta} />

			<Navbar />

			<main>
				{/* Hero */}
				<section className="text-white py-20 my-20">
					<div className="container mx-auto text-black dark:text-white">
						{/* Tagline */}
						<h1 className="mobile:text-2xl tablet:text-3xl laptop:text-4xl desktop:text-5xl xl:text-6xl font-MetropolisMedium text-center text-gray-900 dark:text-gray-200 mt-20 mb-10">
							Creating
							Real{" "}
							<span className="type-indexHero text-tertiary font-MetropolisExtraBold"></span>
						</h1>

						{/* Our Products */}
						<div className="grid md:grid-cols-2 grid-cols-1 justify-center mt-20 gap-6 mx-6 max-w-6xl md:mx-auto md:mt-40">
							{/* Card */}
							<div className="p-6 w-full flex bg-foreground dark:bg-foregroundDark rounded-xl shadow-xl hover:scale-105 transform transition duration-300 ease-in-out">
								{/* Image */}
								<div className="w-12 h-12 inline-flex items-center justify-center mb-4 flex-shrink-0">
									<img
										src={require("../assets/images/crclLogo.png")}
										className="rounded-lg shadow-xl bg-foreground p-1"
									/>
								</div>

								{/* Product Detials */}
								<div className="flex-grow pl-6">
									{/* Title */}
									<h2 className="text-black dark:text-white text-xl title-font font-MetropolisBold text-left mb-2">Crcl | Social Media. Reimagined.</h2>

									{/* Description */}
									<p className="leading-relaxed text-sm font-MetropolisMedium text-left">When you think of social interaction, think of Crcl. The world's first{" "}<b>TRUE</b>{" "} social platform, designed to be everything social media was supposed to be, and more.
										<br />
										<b>A. Whole. Lot. More.</b>
									</p>

									{/* CTA */}
									<div className="flex justify-left">
										<a href="https://crcl.app/beta" className="mt-3 text-tertiary inline-flex items-center font-MetropolisBold hover:scale-105 transform transition duration-300 ease-in-out">
											Beta Access
											<NavigateNextRoundedIcon className="w-4 h-4 font-bold" />
										</a>
									</div>
								</div>
							</div>

							{/* Card */}
							<div className="p-6 w-full flex bg-foreground dark:bg-foregroundDark rounded-xl shadow-xl hover:scale-105 transform transition duration-300 ease-in-out">
								{/* Image */}
								<div className="w-12 h-12 inline-flex items-center justify-center text-indigo-500 mb-4 flex-shrink-0">
									<img
										src={require("../assets/images/crclBusinessLogo.png")}
										className="rounded-lg shadow-xl bg-foreground p-1"
									/>
								</div>

								{/* Product Detials */}
								<div className="flex-grow pl-6">
									{/* Title */}
									<h2 className="text-black dark:text-white text-xl title-font font-MetropolisBold text-left mb-2">
										Crcl
										Business
									</h2>

									{/* Description */}
									<p className="leading-relaxed text-sm font-MetropolisMedium text-left">
										Crcl
										Business
										is
										the
										business
										solution
										for
										the
										modern
										business.
										With
										features
										like
										a
										society-based
										POS,
										marketing
										that
										works, real-time analytics, and more, this is the solution every
										small
										business{" "}
										<b>
											NEEDS
										</b>

										.
									</p>

									{/* CTA */}
									<div className="flex justify-left">
										<a
											href="https://crcl.app/beta"
											className="mt-3 text-tertiary inline-flex items-center font-MetropolisBold hover:scale-105 transform transition duration-300 ease-in-out"
										>
											In
											Early
											Development...
											{/* <NavigateNextRoundedIcon className="w-4 h-4 font-bold" /> */}
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				{/* About Us */}
				<section className="py-20 my-32">
					<div className="container mx-auto">
						{/* Title */}
						<h2 className="text-4xl font-MetropolisBold text-center text-gray-900 dark:text-gray-200 mb-10">
							Our
							Story
						</h2>

						{/* Description */}
						<p className="text-lg font-MetropolisMedium text-center text-gray-600 dark:text-gray-400 max-w-4xl mx-auto">
							Although
							founded
							in
							2022,
							Creating
							Real
							has
							a
							history
							that
							dates
							back
							to
							2016.
							What
							you
							know
							as
							a
							software
							development
							company,
							was
							once
							a
							dream
							that
							seemed
							too
							far
							fetched.
							Every
							aspect
							of
							our
							work,
							from
							development
							to
							design,
							is
							done
							with
							the
							utmost
							care
							and
							precision.
							Our
							technologies
							are
							curated
							towards
							a
							modern
							society,
							yet
							are
							built
							to
							last.
						</p>

						{/* Read More */}
						<div className="flex justify-center mt-10">
							<a
								href="/newsroom/article?id=our-story"
								className="text-tertiary inline-flex items-center font-MetropolisBold hover:scale-105 transform transition duration-300 ease-in-out"
							>
								Read
								More
								<NavigateNextRoundedIcon className="w-4 h-4 font-bold" />
							</a>
						</div>
					</div>
				</section>

				{/* Roadmap */}
				<section className="text-white py-20 my-32">
					<div className="container mx-auto">
						{/* Title */}
						<h2 className="text-4xl font-MetropolisBold text-center text-gray-900 dark:text-gray-200 mb-10">
							Roadmap
						</h2>

						{/* Description */}
						<p className="text-lg font-MetropolisMedium text-center text-gray-600 dark:text-gray-400 max-w-4xl mx-auto">
							The
							future
							is
							bright
							for
							Creating
							Real.
							We
							believe
							transparency
							is
							part
							of
							that
							future.
							We
							intend
							to
							update
							our
							roadmap
							as
							we
							progress
							through
							our
							journey.
							Below
							is
							a
							brief
							overview
							of
							our
							current
							roadmap,
							separated
							into
							three
							sections:
							Now,
							Soon,
							and
							Future.
						</p>

						{/* Three Sections (now, soon, future) */}
						<div className="grid lg:grid-cols-3 grid-cols-1 justify-center mt-10 gap-6 mx-6 mx-6 max-w-6xl md:mx-auto">
							{/* Now */}
							<div className="p-6 md:w-full flex bg-foreground dark:bg-foregroundDark rounded-xl shadow-xl hover:scale-105 transform transition duration-300 ease-in-out">
								{/* Image */}
								<AccessTimeRoundedIcon className="text-black dark:text-white" />

								{/* Product Detials */}
								<div className="flex-grow pl-6">
									{/* Title */}
									<h2 className="text-black dark:text-white text-xl title-font font-MetropolisBold text-left mb-2">
										Now
									</h2>

									{/* Description */}
									<p className="leading-relaxed text-sm font-MetropolisMedium text-left text-black dark:text-white">
										Our current focus is on creating our first product, Crcl. This comes with intentions to set the standard not only for our future products, but for them industry as a whole.
									</p>

									{/* CTA */}
									<div className="flex justify-left">
										<a
											href="https://crcl.app/beta"
											className="mt-3 text-tertiary inline-flex items-center font-MetropolisBold hover:scale-105 transform transition duration-300 ease-in-out"
										>
											Beta
											Access
											<NavigateNextRoundedIcon className="w-4 h-4 font-bold" />
										</a>
									</div>
								</div>
							</div>

							{/* Soon */}
							<div className="p-6 md:w-full flex bg-foreground dark:bg-foregroundDark rounded-xl shadow-xl hover:scale-105 transform transition duration-300 ease-in-out">
								{/* Image */}
								<HourglassBottomRoundedIcon className="text-black dark:text-white" />

								{/* Product Detials */}
								<div className="flex-grow pl-6">
									{/* Title */}
									<h2 className=" text-black dark:text-white text-xl title-font font-MetropolisBold text-left mb-2">
										Soon
									</h2>

									{/* Description */}
									<p className="leading-relaxed text-sm font-MetropolisMedium text-left text-black dark:text-white">
										Our next product, Crcl Business, is currently in the early development phase. The business solution for the modern business, it builds off the community we are creating with Crcl.
									</p>

									{/* CTA */}
									<div className="flex justify-left">
										<a
											href="https://crcl.app/beta"
											className="mt-3 text-tertiary inline-flex items-center font-MetropolisBold hover:scale-105 transform transition duration-300 ease-in-out"
										>
											In
											Early
											Development...
											{/* <NavigateNextRoundedIcon className="w-4 h-4 font-bold" /> */}
										</a>
									</div>
								</div>
							</div>

							{/* Future */}
							<div className="p-6 md:w-full flex bg-foreground dark:bg-foregroundDark rounded-xl shadow-xl hover:scale-105 transform transition duration-300 ease-in-out">
								{/* Image */}
								<UpdateRoundedIcon className="text-black dark:text-white" />

								{/* Product Detials */}
								<div className="flex-grow pl-6">
									{/* Title */}
									<h2 className="text-black dark:text-white text-xl title-font font-MetropolisBold text-left mb-2">
										Future
									</h2>

									{/* Description */}
									<p className="leading-relaxed text-sm font-MetropolisMedium text-left text-black dark:text-white">
										In the future we look to expand with more products, features, and services. We aim to be the go-to company for all things software, and we are working hard to make that a reality.
									</p>

									{/* CTA */}
									<div className="flex justify-left">
										<a
											href="https://crcl.app/beta"
											className="mt-3 text-tertiary inline-flex items-center font-MetropolisBold hover:scale-105 transform transition duration-300 ease-in-out"
										>
											In
											Concept
											Phase...
											{/* <NavigateNextRoundedIcon className="w-4 h-4 font-bold" /> */}
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* See Full Roadmap */}
					<div className="flex justify-center mt-10">
						<p className="text-tertiary font-MetropolisBold hover:scale-105 transform transition duration-300 ease-in-out">
							Full Roadmap Coming Soon...
						</p>
						{/* <a
							href="/roadmap"
							className="text-tertiary inline-flex items-center font-MetropolisBold hover:scale-105 transform transition duration-300 ease-in-out"
						>
							See
							Full
							Roadmap
							<NavigateNextRoundedIcon className="w-4 h-4 font-bold" />
						</a> */}
					</div>
				</section>

				{/* Newsroom */}
				<section className="py-20 my-32">
					<div className="container mx-auto">
						{/* Title */}
						<h2 className="text-4xl font-MetropolisBold text-center text-gray-900 dark:text-gray-200 mb-10">
							Newsroom
						</h2>

						{/* Description */}
						<p className="text-lg font-MetropolisMedium text-center text-gray-600 dark:text-gray-400 max-w-4xl mx-auto">
							Stay
							up
							to
							date
							with
							everything
							Creating
							Real.
							From
							product
							launches
							to
							company
							updates,
							our
							newsroom
							is
							the
							best
							place
							to
							stay
							informed.
						</p>

						{/* Three latest articles */}
						{/* Articles */}
						<div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 max-w-6xl mx-auto mt-10">
							{newsletters.map(
								(
									newsletter,
									index
								) => (
									// Article Card
									<article
										className="overflow-hidden bg-foreground dark:bg-foregroundDark font-MetropolisBold rounded-3xl shadow-lg hover:scale-105 transform transition duration-500 ease-in-out"
										key={
											index
										}
									>
										{/* Link to Article */}
										<a
											href={
												`/newsroom/article?id=` +
												newsletter.id
											}
											className="no-underline text-black"
										>
											<section className="text-gray-600 body-font p-6">
												<div className="h-full flex items-start">
													{/* Date */}
													<div className="w-12 flex-shrink-0 flex flex-col text-center leading-none">
														{/* Month (converted to MMM) */}
														<span className="uppercase text-tertiary pb-2 mb-2 border-b-2 border-tertiary font-MetropolisBold">
															{
																newsletter.month
															}
														</span>

														{/* Day */}
														<span className="font-medium text-lg text-gray-800 dark:text-gray-300 title-font leading-none font-MetropolisMedium">
															{newsletter.timestamp.substring(
																3,
																5
															)}
														</span>
													</div>

													{/* Content */}
													<div className="pl-6 justify-left w-full">
														{/* Category */}
														<h2 className="text-xs rounded p-2 bg-blue-100 dark:bg-tertiary text-tertiary dark:text-white font-MetropolisSemiBold mb-3 text-left uppercase">
															{
																newsletter.category
															}
														</h2>

														{/* Title */}
														<h1 className="text-xl text-gray-900 dark:text-gray-200 mb-3 font-MetropolisBold text-left">
															{
																newsletter.title
															}
														</h1>

														{/* Preview */}
														<p className="font-MetropolisMedium text-left text-sm">
															{
																newsletter.preview
															}
														</p>
													</div>
												</div>
											</section>
										</a>
									</article>
								)
							)}
						</div>

						{/* See All Articles */}
						<div className="flex justify-center mt-10">
							<a
								href="/newsroom"
								className="text-tertiary inline-flex items-center font-MetropolisBold hover:scale-105 transform transition duration-300 ease-in-out"
							>
								See
								All
								Articles
								<NavigateNextRoundedIcon className="w-4 h-4 font-bold" />
							</a>

						</div>

					</div>
				</section>

				{/* Contact Us */}
				<section className="text-white py-20 my-32">
					<div className="container mx-auto">
						{/* Title */}
						<h2 className="text-4xl font-MetropolisBold text-center text-gray-900 dark:text-gray-200 mb-10">
							Contact
							Us
						</h2>

						{/* Description */}
						<p className="text-lg font-MetropolisMedium text-center text-gray-600 dark:text-gray-400 max-w-4xl mx-auto">
							Have
							a
							question,
							comment,
							or
							concern?
							We
							would
							love
							to
							hear
							from
							you.
							Our
							team
							is
							always
							ready
							to
							help,
							and
							we
							are
							always looking for feedback.
						</p>

						{/* Read More */}
						<div className="flex justify-center mt-10">
							<a href="/contact" className="text-tertiary inline-flex items-center font-MetropolisBold hover:scale-105 transform transition duration-300 ease-in-out">
								Contact Us <NavigateNextRoundedIcon className="w-4 h-4 font-bold" />
							</a>
						</div>
					</div>
				</section>
			</main>

			<Footer />
		</div >
	);

}

export default Home