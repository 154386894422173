import './App.css';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Newsroom from './pages/Newsroom';
import Software from './pages/Software';
import Team from './pages/Team';
import NewsroomIndividual from './pages/Article';
import TeamInividual from './pages/Employee';

function App() {
  return (
    <BrowserRouter>
      <div className="App bg-background dark:bg-backgroundDark">
        <Routes>
          <Route path="/contact" element={<Contact />} />
          <Route path="/newsroom" element={<Newsroom />} />
          <Route path="/newsroom/article" element={<NewsroomIndividual />} />
          {/* <Route path="/software" element={<Software />} /> */}
          <Route path="/ourteam" element={<Team />} />
          <Route path="/ourteam/employee" element={<TeamInividual />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
