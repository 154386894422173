import React from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import NewspaperRoundedIcon from "@mui/icons-material/NewspaperRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";

const Footer = () => {

    const [year, setYear] = useState(new Date().getFullYear());

  return (
    <div>

      <footer className="text-gray-600 body-font h-auto">
        <div className="container px-5 pt-2 pb-6 max-w-6xl mx-auto flex items-center sm:flex-row flex-col">
          
          {/* Creating Real Logo */}
          <a className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
            <img src={require('../assets/images/creatingRealLogo.png')} className="w-12 h-12 rounded-full" />
            <span className="ml-3 text-xl font-MetropolisBold text-transparent bg-clip-text bg-gradient-to-r from-secondary via-secondary to-tertiary">Creating Real</span>
          </a>

          {/* Copyright */}
          <p className="text-xs text-gray-600 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-300 sm:py-2 sm:mt-0 mt-4 font-MetropolisBold">© {year} Creating Real LLC</p>

          {/* Social Media Icons */}
          <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">

            {/* Newsroom */}
            <Link to="/newsroom" className="ml-3 text-gray-500 h-4 w-4">
              <NewspaperRoundedIcon className="text-gray-500" fontSize="small" />
            </Link>

            {/* Instagram */}
            <Link to="https://www.instagram.com/creating.real/" className="ml-3 text-gray-500 h-4 w-4">
              <InstagramIcon className="text-gray-500" fontSize="small" />
            </Link>

            {/* Twitter (X) */}
            <Link to="https://twitter.com/creating_real" className="ml-3 text-gray-500 h-4 w-4">
              <XIcon className="text-gray-500" fontSize="small" />
            </Link>
            
          </span>

        </div>
      </footer>

    </div>

  );

};





export default Footer;